.MuiDialogTitle-root {
   text-align: center;
}

.MuiTypography-root .MuiTypography-body1 {
   font-family: Montserrat;
   font-weight: 600;
   font-style: normal;
   font-size: 24px;
   line-height: 31.5px;
}
.MuiDialog-container .MuiDialog-paperWidthSm {
   min-width: 85%;
   max-width: 95%;
   min-height: 85%;
   max-height: 95%;
}

.MuiBox-root-25 {
   padding-left: 15%;
   padding-right: 15%;
}

.MuiPaper-root .MuiCardContent-root {
   padding-left: 12.5%;
}

.MuiPaper-root .MuiCardActions-root {
   padding-left: 85%;
}

.MuiFormControl-root .MuiFormGroup-root {
   flex-direction: row;
}

.MuiCardContent-root .MuiBox-root{
   margin:0;
   padding-bottom: 8px;
}

#titleDescription {
   padding-left: 12.5%;
   padding-right: 12.5%;
}

.photoBox {
   padding-right: 10%!important;
}

.MuiBox-root .MuiFormControl-root {
   min-width: 120px;
}

.MuiFormControl-root .MuiInputLabel-root {
   display: none;
}

fieldset.MuiFormControl-root {
   padding-left: 39%;
   display: -webkit-inline-box;
}