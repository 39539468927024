.MuiTableHead-root {
   background-color: #94a2aa;
}

#root .MuiTableHead-root .MuiTableCell-head {
   font-weight: bold;
   font-size: 14px;
   font-style: normal;
   font-family: "Montserrat", sans-serif;
   color: #ffffff;
   line-height: 22px;
}

#root .MuiTableCell-root {
   font-weight: 500;
   font-family: "Montserrat", sans-serif;
   line-height: 20px;
   font-size: 14px;
   font-style: normal;
}

#root .MuiTableSortLabel-root {
   color: #ffffff;
}

#root .MuiTableSortLabel-icon {
   color: #ffffff;
}

#enhanced-table-checkbox {
   color: #f66000;
   text-decoration: underline;
}

#root .MuiTableCell-alignRight {
   text-align: left;
   flex-direction: row;
}

#root .makeStyles-paper-2 {
   margin-left: 7.5%;
   margin-right: 7.5%;
   width: 85%;
}

#root #copyItem {
   text-align: right;
}

#titleSpan {
   margin-left: 200px;
}